@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

a{
    color: inherit;
}
#send-success{
    width: 65vw;
    margin: 0px auto;
    height: 85vh;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#container-message{
    width: 80%;
    height: 80%;
    box-shadow: 0.5px 0.5px 5px rgba(0,0,0,0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#container-message h3 , #container-message p{
    width: 500px;
    text-align: center;
    margin-bottom: 5vh;
    color: rgba(46, 95, 132, 1);
}

.img-success{
    width: 150px;
    height: 150px;
    margin-bottom: 3vh;
}

.img-success img{
    width: 150px;
    height: 150px;
    margin-bottom: 3vh;
}

.btn-send-home {
    width: 200px;
    height: 50px;
    background-color:rgba(46, 95, 132, 0.9);
    font-size: 1em;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    letter-spacing: 0.05em;
    transition: all 0.3s;
    cursor: pointer;
}

.btn-send-home:hover{
    background-color:rgba(46, 95, 132, 1);
    transform: scale(1.05);
    transition: all 0.3s;
}

.btn-send-home:active{
    transform: scale(0.95);
    transition: all 0.3s;
}
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
}

a{
    color: inherit;
}
header {
    width: 100%;
    height: 9vh;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    background-color: #ffffff;
    z-index: 1000;
    top: 0;
}

header div {
    margin: 0 auto;
    width: 65vw;
    height: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header div img {
    width: 10vw;
    object-fit: contain;
}

header div h1 {
    font-size: 2.5em;
    color: #2E5F84;
}
footer {
    width: 100%;
    height: 9vh;
    position: relative;
    bottom: 0;
    z-index: 100000;
    background-color: #ffffff;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.2);
}

footer div {
    margin: 0 auto;
    width: 65vw;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}

footer div h3 {
    font-size: 1em;
    letter-spacing: 0.05em;
    color: #2E5F84;
}
.body-content {
    width: 100%;
    min-height: calc(100vh - 18vh);
    height: auto;
    background-color: #ffffff;
    margin-top: 9vh;
    padding: 2vh;
}

p {
    color: #7E7D7D;;
}
.upload-content-template{
    width: 100%;
    height: 85%;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgba(46, 95, 132, 0.5);
    font-size: 5em;
    transition: all 0.5s;
    cursor: pointer;
    border-radius: 5px;
}

#extensions-info{
    padding: 10px;
    width: 100%;
    border: none;
    letter-spacing: 0.05em;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
}

.hover-icon{
    color: rgba(46, 95, 132, 0.7);
    font-size: 1em;
    transition: all 0.5s;
}

.upload-content-template:hover > .hover-icon{
    color: rgba(46, 95, 132, 1);
    transform: scale(1.2); 
    transition: all 0.5s;
}

.upload-content-template p {
    width: 100%;
    display: flex;
    text-align: center;
    color: rgba(46, 95, 132, 1);
    font-size: 0.3em;
    border: none !important;
}

#btn-upload{
    width: 80%;
    height: 50px;
    border-radius: 3px;
    background-color: rgba(46, 95, 132, 1);
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.25em;
    margin-top: 5vh;
    cursor: pointer;
}

.info-notify{
    font-size: 1em;
}

#uploadTemplate{
    display: none;
}


.template-radio{
    display: none;
}

.template-radio:checked ~ .container-template-box{
    box-shadow: 0.8px 0.8px 10px rgba(46, 95, 132, 0.5);
}

.template-radio:hover ~ .container-template-box{
    transform: scale(1.01);
    transition: all 0.3s;
    cursor: pointer;
    box-shadow: 1px 0.5px 5px rgba(0, 0, 0, 0.2);
}

.template-radio:active ~ .container-template-box{
    transform: scale(0.98);
}

.container-template-box{
    width: 100%;
    height: 85%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: all 0.3s;
}

.template-img{
    width: 100%;
    height: 85%;
    border-radius: 4px 4px 0px 0px;
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.template-img img{
    width: 100%;
    height: 100%;
}

.template-img-sup{
    position: relative;
    z-index: 2;
    margin-top: -350px;
    border-radius: 4px 4px 0px 0px;
    min-width: 100%;
    height: 100%;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-img-sup:hover{
    background-color: rgba(46, 95, 132, 0.7);
    transition: all 0.5s;
}

.btn-img-view{
    font-size: 30px;
    font-weight: bolder;
    color: rgba(46, 95, 132, 1);
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    height: 170px;
    width: 170px;
    border-radius: 5px;
    cursor: pointer;
    border-radius: 50%;
    visibility: hidden;
}

.template-img-sup:hover .btn-img-view{
    visibility: visible;
    transition: all 0.7s;
}

.btn-img-view:hover{
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.5s;
}

.template p{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-content{
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.check-button{
    height: 30px;
    width: 30px;
    background-color: #FFFFFF;  
    border-radius: 50%; 
    padding: 4px;
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
    box-sizing: border-box;
}

#check-on{
    height: 20px;
    width: 20px;
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
    background-color: #2E5F84;  
    border-radius: 50%; 
}

@-webkit-keyframes BackCheckIcon {
    0%   {transform: scale(0); background-color: rgba(46, 95, 132, 0.1); border: none;}
    100% {transform: scale(1.2); background-color: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.2);}
}

@keyframes BackCheckIcon {
    0%   {transform: scale(0); background-color: rgba(46, 95, 132, 0.1); border: none;}
    100% {transform: scale(1.2); background-color: #FFFFFF; border: 1px solid rgba(0, 0, 0, 0.2);}
}

@-webkit-keyframes checkIcon {
    5%   {transform: scale(0); background-color: rgba(46, 95, 132, 0.2);}
    40%  {transform: scale(0.8); background-color: rgba(46, 95, 132, 0.8);}
    80%  {transform: scale(1.4); background-color: rgba(46, 95, 132, 0.8);}
    100% {transform: scale(0.9); background-color: rgba(46, 95, 132, 1);}
}

@keyframes checkIcon {
    5%   {transform: scale(0); background-color: rgba(46, 95, 132, 0.2);}
    40%  {transform: scale(0.8); background-color: rgba(46, 95, 132, 0.8);}
    80%  {transform: scale(1.4); background-color: rgba(46, 95, 132, 0.8);}
    100% {transform: scale(0.9); background-color: rgba(46, 95, 132, 1);}
}

.container-confirm-delete{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    background-color: rgba(0, 0, 0, 0.7);
}

.box-confirm-delete{
    width: 500px;
    height: 350px;
    border: rgba(0, 0, 0, 0.2);
    box-shadow: 0.5px 0.5px 5px rgba(255, 255, 255, 0.5);
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.box-confirm-delete h3 {
    font-size: 18px !important;
    color: #2e5f84;
}

.box-confirm-delete div {
    margin-top: 40px;
    width: 80%;
    display: flex;
    justify-content: space-around;
}
.body-modal{
    width: 100%;
    min-height: 100px;
    height: auto;
    padding: 20px;
}

.btn-close{
    cursor: pointer;
    font-size: 45px;
    color: #2e5f84;
    transition: all 0.3s;
}

.header-modal h2{
    color: #2e5f84;
}

.btn-close:hover{
    transform: scale(1.1);
    transition: all 0.3s;
}

hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    margin-bottom: 3vh;
}

.div-form-to{
    width: 48%;
    height: auto;
}

.list-emails{
    width: 100%;
    min-height: 300px;
    max-height: 500px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 8px;
}

.form-div-list-emails{
    width: 48%;
    min-height: 100%;
}

.item-list-emails{
    min-width: 100%;
    min-height: 30px !important;
    padding: 6px;
    border-radius: 10px;
    color: rgb(141, 141, 140) !important;
    font-weight: normal !important;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(126, 125, 125, 0.15);
    cursor: pointer;
    margin-bottom: 5px;
}

#list-email-delete, #list-email-edit{
    font-size: 18px !important;
    transition: all 0.5s;
}

#list-email-edit{
    margin-right: 5px;
}

#list-email-delete:hover{
    font-size: 20px !important;
    transition: all 0.5s;
    color: rgb(143, 8, 8);
}

#list-email-edit:hover{
    font-size: 20px !important;
    transition: all 0.5s;
    color: rgb(197, 178, 11);
}

#title-variables{
    width: 100%;
    text-align: center;
    margin-bottom: 3vh;
    color: #2e5f84;
}
.modal-box{
    margin-top: 15vh;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-animation-name: modal-open;
            animation-name: modal-open;
    -webkit-animation-duration: 0.2s;
            animation-duration: 0.2s;
}

.modal-container{
    width: 1000px;
    max-height: 675px;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 1px 0.5px 5px rgba(0, 0, 0, 0.3);
    transition: 0.5s all;
    -webkit-animation-name: modal-open;
            animation-name: modal-open;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
}

@-webkit-keyframes modal-open {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes modal-open {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.header-modal{
    width: 100%;
    height: 70px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1) ;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 25px;
    padding-right: 25px;
}

.footer-modal{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1) ;
}

.btn-to-email-box{
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 25px;
}

.btn-to-email-box p{
    width: 100%;
    height: 20px;
    margin-bottom: 0.5vh;
    font-weight: bold;
    color: rgb(126, 125, 125);
}

.btn-to-email{
    width: 100px;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
    color: rgb(209, 206, 206);
    font-size: 33px;
}

.btn-to-email:hover{
    transform: scale(1.05);
    cursor: pointer;
    box-shadow: 0.5px 0.5px 5px rgba(0, 0, 0, 0.2);
    transition: all 0.3s;
}

.btn-to-email:active{
    transform: scale(0.95);
    transition: all 0.3s;
}

.tooltipEffect {
    max-width: 450px !important;
    min-height: auto !important;
    color:#ffffff !important; 
    padding: 20px !important; 
    background-color: #000000 !important;
    word-spacing: 0.1em !important;
    letter-spacing: 0.03em !important;  
}

.tooltipEffect h3{
    width: 100%;
    text-align: center;  
    margin-bottom: 1vh;
    font-size: 15px !important;
}

.tooltipEffect p{
    width: 100%;
    text-align: center;
    margin-bottom: 15px !important; 
    color: rgb(209, 206, 206);
    font-size: 13px !important;
}

input[type='file'] {
    display: none;
}




.box-attachments{
    width: 100%;
    min-height: 150px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.div-attachments{
    width: 48%;
    min-height: 60%;
}

.upload-content-attach{
    width: 100%;
    height: 250px;
    border: 1px dashed rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(46, 95, 132, 0.5);
    font-size: 5em;
    transition: all 0.5s;
    cursor: pointer;
    border-radius: 5px;
}

.hover-icon{
    color: rgba(46, 95, 132, 0.7);
    font-size: 1em;
    transition: all 0.5s;
}

.upload-content-attach:hover > .hover-icon{
    color: rgba(46, 95, 132, 1);
    transform: scale(1.2); 
    transition: all 0.5s;
}

#uploadFile{
    display: none;
}

ul{
    margin: 0;
    padding: 0;
    list-style: none;
    max-height: 500px;
    overflow: auto;
}

li{
    width: 100%;
    min-height: 40px;
    padding: 9px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 5px;
    font-size: 15px;
    color: rgba(46, 95, 132, 0.5);
    margin-bottom: 7px;
}

.content-all {
    width: 65vw;
    height: inherit;
    margin: 0 auto;
}

.templates-content{
    width: 100% ;
    min-height: 0px;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.templates-content h2{
    width: 100%;
    height: 50px;
    font-size: 1.8em;
    color: #2E5F84;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(46, 95, 132, 0.1);
    box-sizing: border-box;
}

.templates-options {
    margin-top: 5vh;
    width: 100%;
    min-height: 40vh;
    padding-left: 1vw;
    padding-right: 1vw;
}

.templates-options form p {
    width: 100%;
    height: 20%;
    font-size: 1em;
    color: #7E7D7D;
}

.template-box{
    width: 100%;
    height: 100%;
    margin-top: 5vh;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.template{
    width: 18vw;
    height: 50vh;
    margin-bottom: 4vh;
}

.btn-next-box{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    padding-left: 1vw;
    padding-right: 1vw;
}

.btn-next{
    width: 150px;
    height: 50px;
    background-color:rgba(46, 95, 132, 0.9);
    font-size: 1em;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    letter-spacing: 0.05em;
    transition: all 0.3s;
    cursor: pointer;
}

.btn-next:hover{
    background-color:rgba(46, 95, 132, 1);
    transform: scale(1.05);
    transition: all 0.3s;
}

.btn-next:active{
    transform: scale(0.95);
    transition: all 0.3s;
}

@media (max-width: 1050px) 
{
    .content-all{
        width: 85vw;
    } 

    .template-box{
        flex-direction: column;
    }

    .template{
        width: 100%;
    }
    
}

/* Modal dos dados de E-mail */

.email-box{
    width: 50%;
    min-height: 100px;
}

.attachments-box {
    width: 43%;
    min-height: 100px;
}

.form-div{
    width: 100%;
    height: 70px;
    margin-bottom: 5vh;
}

.form-attach{
    width: 100%;
    min-height: 70px;
    margin-bottom: 5vh;
}

.form-div-sub{
    width: 100%;
    height: 70px;
    margin-bottom: 5vh;
}

.form-div-sub + .form-div-sub {
    margin-left: 10px;
}

.form-div p, .form-div-sub p, .form-attach p, .form-div-list-emails p{
    width: 100%;
    height: 20px;
    margin-bottom: 0.5vh;
    font-weight: bold;
    color: rgb(126, 125, 125);
}

.form-div p::first-letter{
    text-transform: uppercase;
}

.form-div input, .form-div-sub input{
    width: 100%;
    height: 45px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    font-size: 14px;
    color: rgb(105, 105, 105);
    padding-left: 10px;
}

.form-div input::-webkit-input-placeholder, .form-div-sub input::-webkit-input-placeholder{
    color: rgb(209, 206, 206);
}

.form-div input:-ms-input-placeholder, .form-div-sub input:-ms-input-placeholder{
    color: rgb(209, 206, 206);
}

.form-div input::-ms-input-placeholder, .form-div-sub input::-ms-input-placeholder{
    color: rgb(209, 206, 206);
}

.form-div input::placeholder, .form-div-sub input::placeholder{
    color: rgb(209, 206, 206);
}

.error-input{
    width: 100%;
    font-size: 12px;
    padding-top: 5px;
    color: rgba(155, 13, 13, 0.6) !important; 
    text-align: center;
}

.email-div{
    width: 100%;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1050px) 
{

    .email-box{
        width: 100%;
    }

    .attachments-box{
        width: 100%;
    }
    
}

#info-emails{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    font-size: 14px;
    color: rgb(161, 160, 160);
}

#to-div{
    margin-top: 0vh !important;
    margin-bottom: 8vh;
    display: flex;
}

.info-tooltip{
    cursor: pointer;
    color: #2E5F84;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    display: none;
}
#template-render{
    width: 65vw;
    margin: 0px auto;
    min-height: 91vh;
    padding-top: 100px;
}

.content-template{
    width: 100%;
    min-height: 100%;
}

.content-template h2 {
    margin-top: 1vh;
    width: 100%;
    height: 50px;
    font-size: 1.7em;
    color: #2E5F84;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(46, 95, 132, 0.1);
    box-sizing: border-box;
    margin-bottom: 5vh;
}

#template-box-render{   
    width: 100%;
    min-height: 600px;
    margin-bottom: 5vh;
    overflow: hidden;
}

#back-home{
    width: 50px;
    transform: scale(1.3);
}
#send-success{
    width: 65vw;
    margin: 0px auto;
    height: 85vh;
    padding-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#container-message{
    width: 80%;
    height: 80%;
    box-shadow: 0.5px 0.5px 5px rgba(0,0,0,0.2);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#container-message h3 , #container-message p{
    width: 500px;
    text-align: center;
    margin-bottom: 5vh;
    color: rgba(46, 95, 132, 1);
}

.img-success{
    width: 150px;
    height: 150px;
    margin-bottom: 3vh;
}

.img-success img{
    width: 150px;
    height: 150px;
    margin-bottom: 3vh;
}

.btn-send-home {
    width: 200px;
    height: 50px;
    background-color:rgba(46, 95, 132, 0.9);
    font-size: 1em;
    font-weight: bold;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    letter-spacing: 0.05em;
    transition: all 0.3s;
    cursor: pointer;
}

.btn-send-home:hover{
    background-color:rgba(46, 95, 132, 1);
    transform: scale(1.05);
    transition: all 0.3s;
}

.btn-send-home:active{
    transform: scale(0.95);
    transition: all 0.3s;
}
